@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Geist Sans;
  src: url(../fonts/geist.ttf);
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: Manrope;
  src: url(../fonts/manrope.ttf);
}

@font-face {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  src: url(../fonts//inter.ttf);
}

html {
  font-size: 62.5%;
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
